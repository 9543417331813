import React, { useState } from "react";
import Slider from "react-slick";
import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import Plywood from "../../assets/static/img/64STRUC18.jpg";
import ScreeWood from "../../assets/static/img/screwwood.jpg";

const Offers = () => {
    const carouselFullwidth = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const [readMore, setReadMore] = useState(false);

    const offers = [
        {
            img: "https://www.w-hanson.co.uk/backend/public/uploads/all/FMBh5svMvSOBBPL3fOgJk2YG8aayelVWGIFOmy9Q.png",
            name: "2400 x 1200 x 12.5mm Sq. Edged Plasterboard",
            price: "£8.35",
            slug: "2400-x-1200-x-125mm-sq-edged-plasterboard-vCrzy",
            unit: "/ sheet",
        },
        {
            img: "https://www.w-hanson.co.uk/backend/public/uploads/all/34ZbD7u2yHoLjW8aAfCihUjftrs1pvjH72JZRHrq.png",
            name: "5M X 8M BLUE TARPAULIN TTL58",
            price: "£12.50 ",
            slug: "5m-x-8m-blue-tarpaulin-ttl58-W1hfI",
            unit: " each",
        },
        {
            img: "https://www.w-hanson.co.uk/backend/public/uploads/all/UDPcU0VP1XsC6DnOEiskhNrywlEwdwmo4kJM4td4.png",
            name: "Blue Paper Roll CF8472",
            price: "£2.17",
            slug: "blue-paper-roll-cf8472-AG2AX",
            unit: "/ roll",
        },
        {
            img: Plywood,
            name: "2440 X 1220 X 18mm WBP Elliotis Eucalyptus Shuttering Plywood Builders Grade (EN636-2) (EN314-2 Bond Class 3 Exterior conditions, exposure to weather over substantial periods or continuous exposure to relative high humidity)",
            price: "£16.60",
            slug: "2440-x-1220-x-18mm-ce2-wbp-structural-elliotis-sw-shuttering-plywood-en636-2-en314-2-bond-class-3-exterior-conditions-exposure-to-weather-over-substantial-periods-or-continuous-exposure-to-relative-high-humidity-ARjkS",
            unit: "/ sheet",
        },
        {
            img: "https://www.w-hanson.co.uk/backend/public/uploads/all/nUhxLk5PliQGLDWO67s9tzXqZlZlxqGAqLvCjaT6.png",
            name: "47 x100 Kd C16 Eased Edged Sawn Reg'D ** Treated ** S/W (3.0M,3.6M,4.2M,4.8M)",
            price: "£1.40",
            slug: "kd-c16-eased-edged-sawn-regd--treated--sw-jQRGe",
            unit: "/ m",
        },
        {
            img: "https://www.w-hanson.co.uk/backend/public/uploads/all/CFlMfEkop70xCNRIBY7XbeacJGnCQCY01HkXITme.png",
            name: "25kg All Weather Bag General Purpose Rugby Premium Cement (56)",
            price: "£5.10",
            slug: "25kg-all-weather-bag-general-purpose-rugby-premium-cement-56-gZbnq",
            unit: "/ bag",
        },
        {
            img: ScreeWood,
            name: "TIMCO 5.0 X 100 SOLO WOODSCREW CSK - ZYP 1000",
            price: "£25.00",
            slug: "",
            unit: "/ box",
        },
    ];

    return (
        <div>
            <div className="ps-deal-of-day pb-0">
                <div className="container">
                    <div className="ps-section__header mb-0 border-0 d-flex mobile-flex">
                        <h3
                            style={{
                                fontSize: "21.5px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                                color: "#07395c",
                            }}
                        >
                            Limited Time, Local Pickup Only – Prices So Low, You
                            Won’t Believe It!
                        </h3>
                        <span
                            class=" mx-2 mb-0 text-danger"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                        >
                            Collected from Harrow Depot Only
                        </span>
                    </div>
                    <div className="ps-section__header home4-header-prod d-flex justify-content-between mobile-flex ">
                        <h3 style={{ fontSize: "17px", fontWeight: "500" }}>
                            October 1 Week Promotion (7-OCT{" "}
                            <small>Monday</small> to 11-OCT{" "}
                            <small>Friday</small>)
                        </h3>
                        <span className="text-danger fw-500">
                            {" "}
                            Strictly While Stocks Last! (All October Special +
                            VAT)
                        </span>
                    </div>
                    <div>
                        <Slider
                            {...carouselFullwidth}
                            className="ps-carousel outside"
                        >
                            {offers.map((items, i) => {
                                return (
                                    <div key={i} className=" pl-0 pr-0 ">
                                        <div
                                            className={`ps-product ps-product--inner all-product-card ${
                                                readMore ? "h-100" : ""
                                            }`}
                                            style={{ height: "315px" }}
                                        >
                                            <div className="ps-product__thumbnail offer-img text-center">
                                                <div
                                                    className="ps-product__badge offer-badge"
                                                    style={{
                                                        zIndex: "99",
                                                        // fontSize:"1 5px"
                                                    }}
                                                >
                                                    {items.price}{" "}
                                                    <small>{items.unit}</small>
                                                </div>
                                                <img
                                                    src={items.img}
                                                    alt="No img"
                                                    className="bd-object-fit"
                                                    height="171"
                                                />
                                                {/* </Link> */}
                                            </div>
                                            <div
                                                className={`ps-product__container ${
                                                    items.name?.length > 130
                                                        ? "mt-1"
                                                        : "mt-5"
                                                }`}
                                            >
                                                <a className="ps-product__vendor border-0">
                                                    {readMore
                                                        ? items?.name
                                                        : items?.name?.slice(
                                                              0,
                                                              100
                                                          )}
                                                    {items?.price ==
                                                    "£16.60" ? (
                                                        <a style={{fontSize:"11px", marginLeft:"10px", color:"#07395c"}}
                                                            onClick={() =>
                                                                setReadMore(
                                                                    !readMore
                                                                )
                                                            }
                                                        >
                                                           {readMore ? "Read Less" :"Read More"}
                                                        </a>
                                                    ) : null}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Offers;
