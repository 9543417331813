import { Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import config from "../../config";

const CheckDelivery = ({all_category}) => {

    const [zipcode, setZipCode] = useState("");
  const [delivery, setDelivery] = useState(false);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const onCheckStatus = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("category_id", all_category[0]?.id);
    formData.append("zipcode", zipcode);
    try {
      await axios({
        method: "post",
        data: formData,
        url: `${config.apiUrl}/checkShippingCost`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setShow(true);
        setDelivery(res?.data === "N/A" ? false : true);
        setLoader(false);
        setZipCode("");
        setTimeout(() => {
          setShow(false);
        }, 4000);
      });
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <div className="mb-5">
      <form onSubmit={onCheckStatus}>
        <p
          className=" mb-3  text-black "
          style={{ color: "black", fontSize: "16px" }}
        >
          Check Delivery Status
        </p>
        {show ? (
          <div
            className={`alert ${delivery ? "alert-success" : "alert-danger"}`}
            role="alert"
            style={{ fontSize: "13px" }}
          >
            {delivery
              ? "Awesome! Delivery to your location is available! 🛒✨"
              : "Oops! Delivery isn’t available for your location right now. 😢"}
          </div>
        ) : (
          <div class="ps-delivery-status ">
            <input
              class="coupon-field"
              type="text"
              name="coupon"
              placeholder="Enter Pincode"
              value={zipcode}
              onChange={(e) => setZipCode(e.target.value)}
              required
            />
            <button>{loader ? <Spin className="check" /> : "Check Now"}</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default CheckDelivery;
