import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import config from "../../../config";
import CheckDelivery from "../../../pages/Payment/CheckDelivery";

const WidgetShopCategories = ({all_category}) => {
  const params = useParams();

  return (
    <aside className="widget widget_shop">
      <CheckDelivery all_category={all_category} />
      {/* <h4 className="widget-title mb-3">Categories</h4> */}
      <ul className="ps-list--categories colo-black">
        <li className="main-category">
          {all_category?.length != 0 ? (
            <a>{all_category[0]?.name} </a>
          ) : (
            <a>MDF Skirting & Archtrave </a>
          )}
        </li>
        {all_category?.length != 0 ? (
          <ul>
            {all_category[0]?.sucategories?.map((obj, i) => {
              return (
                <li className="sub-menu-side">
                  <Link
                    to={`/${obj.slug}`}
                    className={params.slug == obj.slug ? "side-active" : ""}
                  >
                    <i className="icon-arrow-right pr-10"></i>
                    {obj.name}
                  </Link>
                  {obj.name == "MDF Skirting & Archtrave" ? (
                    <ul className="sub-menu-skirt">
                      <li>
                        <Link to="/Ogee">Ogee</Link>
                      </li>
                      <li>
                        <Link to="/6mm-Pencil-Round">6mm Pencil Round</Link>
                      </li>
                      <li>
                        <Link to="/Torus">Torus</Link>
                      </li>
                      <li>
                        <Link to="/CPPLC1">CPPLC1</Link>
                      </li>
                      <li>
                        <Link to="/PSE-with-15x4-Groove-17mm-Flat">
                          PSE with 15x4 Groove 17mm Flat
                        </Link>
                      </li>
                      <li>
                        <Link to="/BK247">BK247</Link>
                      </li>
                      <li>
                        <Link to="/Square-Edge">Square Edge</Link>
                      </li>
                      <li>
                        <Link to="/3mm-Pencil-Round">3mm Pencil Round</Link>
                      </li>
                      <li>
                        <Link to="/Chamfered-&-Rounded">
                          Chamfered & Rounded
                        </Link>
                      </li>
                      <li>
                        <Link to="/SP600">SP600</Link>
                      </li>
                      <li>
                        <Link to="/2mm-Bevel">2mm Bevel</Link>
                      </li>
                      <li>
                        <Link to="/Twice-Grooved-10x4-groove-20mm-from-Top">
                          Twice Grooved (10x4 groove) 20mm from Top
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        ) : all_category?.length == 0 ? (
          <ul>
            <li className="sub-menu-side">
              <Link
                to="/Ogee"
                className={params.slug == "Ogee" ? "side-active" : ""}
              >
                <i className="icon-arrow-right pr-10"></i>Ogee
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/6mm-Pencil-Round"
                className={
                  params.slug == "6mm-Pencil-Round" ? "side-active" : ""
                }
              >
                <i className="icon-arrow-right pr-10"></i>6mm Pencil Round
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/Torus"
                className={params.slug == "Torus" ? "side-active" : ""}
              >
                <i className="icon-arrow-right pr-10"></i>Torus
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/CPPLC1"
                className={params.slug == "CPPLC1" ? "side-active" : ""}
              >
                <i className="icon-arrow-right pr-10"></i>CPPLC1
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/PSE-with-15x4-Groove-17mm-Flat"
                className={
                  params.slug == "PSE-with-15x4-Groove-17mm-Flat"
                    ? "side-active"
                    : ""
                }
              >
                <i className="icon-arrow-right pr-10"></i>PSE with 15x4 Groove
                17mm Flat
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/BK247"
                className={params.slug == "BK247" ? "side-active" : ""}
              >
                <i className="icon-arrow-right pr-10"></i>BK247
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/Square-Edge"
                className={params.slug == "Square-Edge" ? "side-active" : ""}
              >
                <i className="icon-arrow-right pr-10"></i>Square Edge
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/3mm-Pencil-Round"
                className={
                  params.slug == "3mm-Pencil-Round" ? "side-active" : ""
                }
              >
                <i className="icon-arrow-right pr-10"></i>3mm Pencil Round
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/Chamfered-&-Rounded"
                className={
                  params.slug == "Chamfered-&-Rounded" ? "side-active" : ""
                }
              >
                <i className="icon-arrow-right pr-10"></i>Chamfered & Rounded
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/SP600"
                className={params.slug == "SP600" ? "side-active" : ""}
              >
                <i className="icon-arrow-right pr-10"></i>SP600
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/2mm-Bevel"
                className={params.slug == "2mm-Bevel" ? "side-active" : ""}
              >
                <i className="icon-arrow-right pr-10"></i>2mm Bevel
              </Link>
            </li>
            <li className="sub-menu-side">
              <Link
                to="/Twice-Grooved-10x4-groove-20mm-from-Top"
                className={
                  params.slug == "Twice-Grooved-10x4-groove-20mm-from-Top"
                    ? "side-active"
                    : ""
                }
              >
                <i className="icon-arrow-right pr-10"></i>Twice Grooved (10x4
                groove) 20mm from Top
              </Link>
            </li>
          </ul>
        ) : null}
      </ul>
    </aside>
  );
};

export default WidgetShopCategories;
