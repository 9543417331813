import React, { useEffect } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import BreadCrumb from "../../components/elements/BreadCrumb";
import {
  Alert,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import swal from "sweetalert";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Payment/CheckoutForm";
import config from "../../config";
import { userAgent } from "next/server";

const Checkout = () => {
  const navigate = useNavigate();
  const [calculated_status, setCalculateStatus] = useState("");
  useEffect(() => {
    const prevItems = JSON.parse(localStorage.getItem("items"));
    // setCalculateStatus(prevItems.some((item) => item.zip_status != 0) == true ? true : false)
    setCalculateStatus(
      prevItems.some((item) => item.zip_status == 0) == true ? false : true
    );
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("auth_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/users/login");
    }
  }, []);
  const [cartItems, setCartItems] = useState([]);
  const [total_price, setTotalPrice] = useState(0);
  const [isNegotiate, setIsNegotiate] = useState(false);
  const [negotiated_price, setNegotiatedPrice] = useState(0);
  const [subTotal_price, setSubTotalPrice] = useState(0);
  const [nonNegotiatedtotal, setNonNegotiatedTotal] = useState(0);

  useEffect(() => {
    const getCartData = async () => {
      const formData = new FormData();
      formData.append("cart_id", localStorage.getItem("cart_id"));
      try {
        const res = await axios.post(`${config.apiUrl}/cartDetails`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        setIsNegotiate(res.data.data.negotiated == 1 ? true : false);

        if (res.data.data.negotiated == 1) {
          setNegotiatedPrice(res.data.data.price);
          const Items = JSON.parse(localStorage.getItem("items"));
          const includes = Items.some((item) => item.negotiate);
          if (includes) {
            const nonNegotiableprice = Items?.filter(
              (obj) => obj.negotiate == true
            )
              .flat()
              .reduce((total, item) => {
                return total + parseFloat(item.price) * item.qty;
              }, 0);
            setTotalPrice(
              parseFloat(res.data.data.price) + parseFloat(nonNegotiableprice)
            );
          } else {
            setTotalPrice(parseFloat(res.data.data.price));
          }
        } else {
          const Items = JSON.parse(localStorage.getItem("items"));
          const includes = Items.some((item) => item.negotiate);
          if (includes) {
            const nonNegotiableprice = Items?.filter(
              (obj) => obj.negotiate == true
            )
              .flat()
              .reduce((total, item) => {
                return total + parseFloat(item.price) * item.qty;
              }, 0);
            setTotalPrice(
              parseFloat(res.data.data.price) + parseFloat(nonNegotiableprice)
            );
          } else {
            setTotalPrice(parseFloat(res.data.data.price));
          }
        }

        const Items = JSON.parse(localStorage.getItem("items"));
        let existingIds = Items.map((obj) => obj.id);
        // Filter array2 to get objects with ids not present in array
        let output = res.data.data.cartData?.filter(
          (obj) => !existingIds.includes(obj.product_id)
        );
        const updatedArray = output.map((item) => ({
          id: item.product_id,
          name: item.name,
          negotiate: false, // Assuming this is a default value
          price: item.price,
          qty: item.qty,
          width: item.width,
          height: item.height,
          length: item.length,
          thumbnail_image: item.photos,
        }));
        const updatedItems = [...Items, ...updatedArray];
        if (output?.length != 0) {
          localStorage.setItem("items", JSON.stringify(updatedItems));
          setCartItems(updatedItems);
          const totalPrice = updatedItems
            ?.filter((obj) => obj.negotiate == false)
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          const nonNegotiableprice = updatedItems
            ?.filter((obj) => obj.negotiate == true)
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          setTotalPrice(totalPrice);
          setNonNegotiatedTotal(nonNegotiableprice);
          setSubTotalPrice(totalPrice);
        } else {
          const Items = JSON.parse(localStorage.getItem("items"));
          setCartItems(Items);
          const includes = Items.some((item) => item.negotiate);

          const totalPrice = Items?.filter((obj) => obj.negotiate == false)
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          const nonNegotiableprice = Items?.filter(
            (obj) => obj.negotiate == true
          )
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          setTotalPrice(totalPrice);

          setSubTotalPrice(totalPrice);
          setNonNegotiatedTotal(nonNegotiableprice);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (localStorage.getItem("cart_id") != null) {
      getCartData();
    } else {
      const Items = JSON.parse(localStorage.getItem("items"));
      setCartItems(Items);
      const totalPrice = Items?.filter((obj) => obj.negotiate == false)
        .flat()
        .reduce((total, item) => {
          return total + parseFloat(item.price) * item.qty;
        }, 0);
      const nonNegotiableprice = Items?.filter((obj) => obj.negotiate == true)
        .flat()
        .reduce((total, item) => {
          return total + parseFloat(item.price) * item.qty;
        }, 0);
      setTotalPrice(totalPrice);
      setNonNegotiatedTotal(nonNegotiableprice);
      setSubTotalPrice(totalPrice);
    }
  }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Checkout",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [payment, setPayment] = useState(false);
  const [same_address, setSameAddress] = useState(true);
  const [method, setMethod] = useState(3);

  function handleChangeMethod(e) {
    setMethod(e.target.value); //e.target.value
  }

  const [shippingfess, setShippingFees] = useState(
    localStorage.getItem("ship") || ""
  );
  const [congestion, setCongestion] = useState(
    localStorage.getItem("congestion")
  );
  const [vatPrice, setVatPrice] = useState(0);
  const [MainTotal, setMainTotal] = useState(0);

  useEffect(() => {
    const mainVatPrice =
      congestion == 1 ? "15.00" : (parseFloat(total_price) * 20) / 100;
    setVatPrice(mainVatPrice);

    setMainTotal(
      parseFloat(total_price) +
        parseFloat(mainVatPrice) +
        parseFloat(shippingfess)
    );
  }, [shippingfess, total_price]);

  // const vatPrice =

  // const MainTotal =

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [billing_country, setBillingCountry] = useState("United Kingdom");
  const [billing_state, setBillingstate] = useState(null);
  const [billing_city, setBillingCity] = useState(null);
  const [billing_code, setBillingCode] = useState(null);
  const [billing_address, setBillingAddress] = useState(null);
  const [shipping_country, setshippingCountry] = useState("United Kingdom");
  const [shipping_state, setshippingstate] = useState(null);
  const [shipping_city, setshippingCity] = useState(null);
  const [shipping_code, setshippingCode] = useState(null);
  const [shipping_address, setShippingAddress] = useState(null);
  console.log(shipping_address)

  useEffect(() => {
    onUserDetail();
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [state_option, setStateOption] = useState([]);
  const [city_ship_option, setCityShipOption] = useState([]);
  const [city_option, setCityOption] = useState([]);

  const onUserDetail = async () => {
    setLoading(true);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/users/${localStorage.getItem("auth_id")}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setName(res.data.users[0].name);
        setEmail(res.data.users[0].email);
        setStateOption(res.data.states);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onChangeState = async (e) => {
    setLoading(true);
    setBillingstate(e.target.options[e.target.selectedIndex].id);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/cities/${e.target.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setCityOption(res.data.cities);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeShippingState = async (e) => {
    setLoading(true);
    setshippingstate(e.target.options[e.target.selectedIndex].id);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/cities/${e.target.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setCityShipOption(res.data.cities);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [noDelivering, setNotDelivering] = useState(false);
  const [deliverOpen, setDeliveryOpen] = useState(false);
  const [deliveryProducts, setDeliveryProducts] = useState([]);

  const onCalCulateCharge = async (e) => {
    e.preventDefault();
    const alProduct = JSON.parse(localStorage.getItem("items"));
    const ProductIds = alProduct.map((obj) => obj.id).join(",");
    const Price = alProduct
      .map((obj) => (parseFloat(obj.price) * parseFloat(obj.qty)).toFixed(2))
      .join(",");

    let ShippingAddress;
    let BillingAddress;
    let PostalCode;
    if (same_address == true) {
      BillingAddress = {
        address: billing_address,
        country: billing_country,
        state: billing_state,
        city: billing_city,
        postal_code: billing_code,
      };
      PostalCode = billing_code;

      ShippingAddress = {
        address: billing_address,
        country: billing_country,
        state: billing_state,
        city: billing_city,
        postal_code: billing_code,
      };
    } else {
      BillingAddress = {
        address: billing_address,
        country: billing_country,
        state: billing_state,
        city: billing_city,
        postal_code: billing_code,
      };

      ShippingAddress = {
        address: shipping_address,
        country: shipping_country,
        state: shipping_state,
        city: shipping_city,
        postal_code: shipping_code,
      };

      PostalCode = shipping_code;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("product_id", ProductIds);
    formData.append("price", Price);
    formData.append("postal_code", PostalCode);
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}/calculateShippingCost`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        const hasNA = res.data?.products.some(
          (item) => item.shipping_cost === "N/A"
        );
        const allNA = res.data?.products.every(
          (item) => item.shipping_cost === "N/A"
        );
        if (allNA) {
          setNotDelivering(true);
        } else if (hasNA && res.data?.products?.length > 1) {
          const NaProduct = res.data?.products
            .filter((item) => item.shipping_cost === "N/A")
            .map((item) => item.product_id);
          const updatedArray = cartItems.map((item) => ({
            ...item,
            delivery: NaProduct?.includes(item.id) ? 0 : 1,
          }));
          setDeliveryProducts(updatedArray);
          setDeliveryOpen(true);
        } else if (hasNA) {
          setNotDelivering(true);
        } else {
          setNotDelivering(false);
          const items = alProduct.map((item) => ({
            ...item,
            zip_status: 1,
          }));
          setCalculateStatus(true);
          localStorage.setItem("items", JSON.stringify(items));
          setPayment(true);
        }
        setShippingFees(res.data.shipping_cost);
        setCongestion(res.data.congestion);
        localStorage.setItem("congestion", res.data.congestion);
        localStorage.setItem("ship", res.data.shipping_cost);

        const userData = {
          shipping_code: same_address == true ? billing_code : shipping_code,
          billing_code: billing_code,
          billing_state: billing_state,
          shipping_state: same_address == true ? billing_state : shipping_state,
          billing_city: billing_city,
          shipping_city: same_address == true ? billing_city : shipping_city,
          billing_address: billing_address,
          shipping_address:
            same_address == true ? billing_address : shipping_address,
        };
        setshippingCode(userData?.shipping_code)
        setshippingstate(userData?.shipping_state)
        setshippingCity(userData?.shipping_city)
        setShippingAddress(userData?.shipping_address)
        localStorage.setItem("auth_users", JSON.stringify(userData));

        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Strip Payment Integration
  const [open, setOpen] = useState(false);

  // Live Public Key
  const PUBLIC_KEY = "pk_live_51LBAz6JaXtAdPEaby8WP5UjKwPG8CKHF0kCCCfexzixAXhGUVhJa1iUclgI5wJtQQIKFk9DaAo445tuxYQtjJys300mWgRMOWv"

  // Testing Public Key
  // const PUBLIC_KEY =
  //   "pk_test_51O9iUSSAu4SQbYbwKYY8lV7aGKR1h7xAZKxjBY8NAK3CtawTmoS5xb5CrN9Vxp5wbaectWNviMVnANiCae1ANvgg00VwGp0z2H";
  const stripePayment = loadStripe(PUBLIC_KEY);

  useEffect(() => {
    if (localStorage.getItem("auth_users")) {
      const isSame =
        JSON.parse(localStorage.getItem("auth_users"))?.billing_code ==
        JSON.parse(localStorage.getItem("auth_users"))?.shipping_code;
      setSameAddress(isSame);

      if (isSame == true) {
        setBillingCode(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_code
        );
        setshippingCode(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_code
        );

        setBillingAddress(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_address
        );
        setShippingAddress(
          JSON.parse(localStorage.getItem("auth_users"))?.shipping_address
        );
        setBillingstate(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_state
        );
        setshippingstate(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_state
        );
        setBillingCity(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_city
        );
        setshippingCity(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_city
        );
      } else {
        setBillingCode(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_code
        );
        setshippingCode(
          JSON.parse(localStorage.getItem("auth_users"))?.shipping_code
        );
        setBillingCity(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_city
        );
        setshippingCity(
          JSON.parse(localStorage.getItem("auth_users"))?.shipping_city
        );
        setBillingstate(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_state
        );
        setshippingstate(
          JSON.parse(localStorage.getItem("auth_users"))?.shipping_state
        );
        setBillingAddress(
          JSON.parse(localStorage.getItem("auth_users"))?.billing_address
        );
        setShippingAddress(
          JSON.parse(localStorage.getItem("auth_users"))?.shipping_address
        );
      }
    }
  }, []);
  // console.log(shipping_city)

  const isNonNegotiable = cartItems?.some((item) => item.negotiate);

  const FinalCheckout = (e) => {
    swal({
      title: "Are you sure?",
      text: "You want to Placed an Order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const alProduct = JSON.parse(localStorage.getItem("items"));
        const ProductIds = alProduct.map((obj) => obj.id).join(",");
        const Widths = alProduct.map((obj) => obj.width).join(",");
        const Heights = alProduct.map((obj) => obj.height).join(",");
        const Lengths = alProduct.map((obj) => obj?.length).join(",");
        const Price = alProduct
          .map((obj) =>
            (
              (parseFloat(obj.price) + (parseFloat(obj.price) * 20) / 100) *
              parseFloat(obj.qty)
            ).toFixed(2)
          )
          .join(",");
        const vatFree = alProduct
          .map((obj) =>
            (parseFloat(obj.price) * parseFloat(obj.qty)).toFixed(2)
          )
          .join(",");
        const Qty = alProduct.map((obj) => obj?.qty).join(",");

        let ShippingAddress;
        let BillingAddress;
        let PostalCode;
        if (same_address == true) {
          BillingAddress = {
            address: billing_address,
            country: billing_country,
            state: billing_state,
            city: billing_city,
            postal_code: billing_code,
          };
          PostalCode = billing_code;

          ShippingAddress = {
            address: billing_address,
            country: billing_country,
            state: billing_state,
            city: billing_city,
            postal_code: billing_code,
          };
        } else {
          BillingAddress = {
            address: billing_address,
            country: billing_country,
            state: billing_state,
            city: billing_city,
            postal_code: billing_code,
          };

          ShippingAddress = {
            address: shipping_address,
            country: shipping_country,
            state: shipping_state,
            city: shipping_city,
            postal_code: shipping_code,
          };

          PostalCode = shipping_code;
        }
        setLoading(true);
        const formData = new FormData();
        formData.append(
          "order_id",
          localStorage.getItem("order_id") != null
            ? localStorage.getItem("order_id")
            : 0
        );
        formData.append("user_id", localStorage.getItem("auth_id"));
        formData.append("product_id", ProductIds);
        formData.append("width", Widths);
        formData.append("height", Heights);
        formData.append("length", Lengths);
        formData.append("price", Price);
        formData.append("vat_free", vatFree);
        formData.append("qty", Qty);
        formData.append("postal_code", PostalCode);
        formData.append("shipping_address", JSON.stringify(ShippingAddress));
        formData.append("billing_address", JSON.stringify(BillingAddress));
        try {
          await axios({
            method: "post",
            url: `${config.apiUrl}/partial_order`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(async (res) => {
            localStorage.setItem("order_id", res.data.order_id);
            // localStorage.setItem("congestion", res.data.congestion);
            // localStorage.setItem("ship", res.data.shipping_cost);
            const userData = {
              shipping_code: PostalCode,
              billing_code: PostalCode,
              billing_state: billing_state,
              shipping_state:
                same_address == true ? billing_state : shipping_state,
              billing_city: billing_city,
              shipping_city:
                same_address == true ? billing_city : shipping_city,
              address:
                same_address == true ? billing_address : shipping_address,
            };
            localStorage.setItem("auth_users", JSON.stringify(userData));
            setPayment(true);

            const stripe = await stripePayment;
            // Create a new checkout session and redirect
            const formDatas = new FormData();
            formDatas.append("order_id", localStorage.getItem("order_id"));
            formDatas.append("amount", parseFloat(MainTotal).toFixed(2));
            const response = await axios({
              method: "post",
              url: `${config.apiUrl}/createCheckoutSession`,
              data: formDatas,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            });
            setLoading(false);
            const session = await response?.data;

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (result.error) {
              // Handle error here
              swal({
                icon: "error",
                title: "Payment Failed",
                text: result.error.message,
                icon: "error",
              });
              console.error(result.error.message);
            }
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    });
  };

  const onRemoveNonDeliver = () => {
    const filteredArray = cartItems
      .filter(
        (item) =>
          !deliveryProducts.some((dp) => dp.id === item.id && dp.delivery === 0)
      )
      .map((item) => ({ ...item, zip_status: 1 }));

    localStorage.setItem("items", JSON.stringify(filteredArray));
    setCartItems(filteredArray);
    setCalculateStatus(true);
    setDeliveryOpen(false);
    setPayment(true);

    const totalPrice = filteredArray
      ?.filter((obj) => obj.negotiate == false)
      .flat()
      .reduce((total, item) => {
        return total + parseFloat(item.price) * item.qty;
      }, 0);
    setSubTotalPrice(totalPrice);

    const VatPrice =
      congestion == 1 ? "15.00" : (parseFloat(totalPrice) * 20) / 100;
    setVatPrice(VatPrice);
    setMainTotal(
      parseFloat(totalPrice) + parseFloat(VatPrice) + parseFloat(shippingfess)
    );
  };

  return (
    <div>
      {isLoading && <Loader />}
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-checkout ps-section--shopping pt-60">
            <div className="container">
              <div
                className="ps-section__header "
                style={{ paddingBottom: "50px" }}
              >
                <h1 style={{ fontSize: "30px", textAlign: "left" }}>
                  Checkout Information
                </h1>
              </div>
              <div className="ps-section__content">
                <div className="ps-form--checkout">
                  <div className="ps-form__content">
                    <form onSubmit={onCalCulateCharge}>
                      <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 custome-checkout">
                          <div className="ps-form__billing-info">
                            <h3 className="ps-form__heading">
                              Contact information
                            </h3>
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Full Name *"
                                    value={name}
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder={"Email or phone number *"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <h3 className="ps-form__heading mt-20">
                              Billing address
                            </h3>

                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    value={billing_country}
                                    onChange={(e) =>
                                      setBillingCountry(e.target.value)
                                    }
                                    required
                                  >
                                    <option value="">Select Country *</option>
                                    <option value="United Kingdom">
                                      United Kingdom
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    id="mySelect"
                                    onChange={onChangeState}
                                    required
                                    disabled={
                                      localStorage.getItem("auth_users") != null
                                    }
                                  >
                                    <option value="">Select State *</option>
                                    {state_option.map((obj, i) => {
                                      return (
                                        <option
                                          value={obj.id}
                                          id={obj.name}
                                          key={i}
                                          selected={billing_state == obj.name}
                                        >
                                          {obj.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {billing_state != null &&
                                  billing_state?.length == 0 ? (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      * State is Required
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    required
                                    onChange={(e) =>
                                      setBillingCity(e.target.value)
                                    }
                                    disabled={
                                      localStorage.getItem("auth_users") != null
                                    }
                                  >
                                    {localStorage.getItem("auth_users") !=
                                    null ? (
                                      <option value="">{billing_city}</option>
                                    ) : null}
                                    <option value="">Select City *</option>

                                    {city_option.map((obj, i) => {
                                      return (
                                        <option
                                          value={obj.name}
                                          key={i}
                                          selected={billing_city == obj.name}
                                        >
                                          {obj.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {billing_city != null &&
                                  billing_city?.length == 0 ? (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      * City is Required
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="postalCode"
                                    placeholder="Postal Code *"
                                    value={billing_code}
                                    onChange={(e) => {
                                      setBillingCode(
                                        e.target.value.replace(/\s/g, "")
                                      );
                                    }}
                                    required
                                    disabled={calculated_status == true}
                                  />
                                  {billing_code != null &&
                                  billing_code?.length == 0 ? (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      * Postal Code is Required
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder="Address *"
                                value={billing_address}
                                onChange={(e) =>
                                  setBillingAddress(e.target.value)
                                }
                                required
                                disabled={calculated_status == true}
                              />
                              {billing_address != null &&
                              billing_address?.length == 0 ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  * Address is Required
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group mt-20">
                              <div className="">
                                <Switch
                                  checked={same_address}
                                  onChange={(e) => setSameAddress(e)}
                                />
                                <label
                                  htmlFor="save-information"
                                  className="ml-20"
                                >
                                  Same Address in Shipping Details
                                </label>
                              </div>
                            </div>

                            {/* Shipping Address Details */}

                            {same_address == false ? (
                              <>
                                <h3 className="ps-form__heading mt-20">
                                  Shipping Address
                                </h3>

                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        value={shipping_country}
                                        onChange={(e) =>
                                          setshippingCountry(e.target.value)
                                        }
                                        disabled={
                                          localStorage.getItem("auth_users") !=
                                          null
                                        }
                                      >
                                        <option value="">
                                          Select Country *
                                        </option>
                                        <option value="United Kingdom">
                                          United Kingdom
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        onChange={onChangeShippingState}
                                        required
                                        disabled={
                                          localStorage.getItem("auth_users") !=
                                          null
                                        }
                                      >
                                        <option value="">Select State *</option>
                                        {state_option.map((obj, i) => {
                                          return (
                                            <option
                                              value={obj.id}
                                              id={obj.name}
                                              key={i}
                                              selected={
                                                shipping_state == obj.name
                                              }
                                            >
                                              {obj.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {shipping_state != null &&
                                      shipping_state?.length == 0 ? (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "12px" }}
                                        >
                                          * State is Required
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        required
                                        onChange={(e) => {
                                          setshippingCity(e.target.value);
                                        }}
                                        disabled={
                                          localStorage.getItem("auth_users") !=
                                          null
                                        }
                                      >
                                        {localStorage.getItem("auth_users") !=
                                        null ? (
                                          <option value="">
                                            {shipping_city}
                                          </option>
                                        ) : null}
                                        <option value="">Select City *</option>
                                        {city_ship_option.map((obj, i) => {
                                          return (
                                            <option value={obj.name} key={i}>
                                              {obj.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {shipping_city != null &&
                                      shipping_city?.length == 0 ? (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "12px" }}
                                        >
                                          * City is Required
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <Input
                                        className="form-control"
                                        type="postalCode"
                                        placeholder="Postal Code *"
                                        value={shipping_code}
                                        onChange={(e) => {
                                          setshippingCode(
                                            e.target.value.replace(/\s/g, "")
                                          );
                                        }}
                                        disabled={calculated_status == true}
                                      />
                                      {shipping_code != null &&
                                      shipping_code?.length == 0 ? (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "12px" }}
                                        >
                                          * Postal Code is Required
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Address *"
                                    value={shipping_address}
                                    onChange={(e) =>
                                      setShippingAddress(e.target.value)
                                    }
                                    disabled={calculated_status == true}
                                  />
                                </div>
                                {shipping_address != null &&
                                shipping_address?.length == 0 ? (
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                  >
                                    * Address is Required
                                  </span>
                                ) : null}
                              </>
                            ) : null}

                            <div className="ps-form__submit">
                              <Link to="/shopping-cart">
                                <a>
                                  <i className="icon-arrow-left mr-2"></i>
                                  Return to Shopping Cart
                                </a>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12  ps-block--checkout-order custome-order">
                          <div className="ps-form__orders">
                            <h3>Your order</h3>
                            <div className="ps-block--checkout-order">
                              <div className="ps-block__content">
                                <figure className="mb-20">
                                  <figcaption>
                                    <strong>Product</strong>
                                    <strong>total</strong>
                                  </figcaption>
                                </figure>
                                {cartItems?.map((item, i) => {
                                  const total =
                                    parseFloat(item.price) *
                                    parseFloat(item.qty);
                                  return (
                                    <figure
                                      className={`ps-block__items pb-0 ${
                                        item?.negotiate
                                          ? "bg-nego-light-blue"
                                          : ""
                                      }`}
                                    >
                                      <div className="d-flex justify-space-between">
                                        <a className="col-9 ">
                                          <strong>
                                            {item.name}
                                            <span>x {item.qty}</span>
                                            {item.type == "Linear" ? (
                                              <p className="varition-size">
                                                H : {item.height}mm , W :{" "}
                                                {item.width}mm , L :{" "}
                                                {item.length}m{" "}
                                              </p>
                                            ) : null}
                                          </strong>
                                        </a>
                                        <a className="col-2 justify-content-center">
                                          <small>£{total?.toFixed(2)}</small>
                                        </a>
                                      </div>
                                      {item.negotiate ? (
                                        <p
                                          className="text-danger "
                                          style={{ fontSize: "11px" }}
                                        >
                                          * Non Negotiable Product as cart is
                                          Already Negotiated
                                        </p>
                                      ) : null}
                                    </figure>
                                  );
                                })}

                                <figure className="mt-30">
                                  <figcaption className="checkout-nego">
                                    <strong>
                                      {isNegotiate
                                        ? "Negotiate Cart"
                                        : "Subtotal"}
                                    </strong>
                                    <small className="text-right">
                                      <strong>
                                        £{subTotal_price?.toFixed(2)}
                                      </strong>
                                    </small>
                                  </figcaption>
                                  {isNegotiate && (
                                    <>
                                      <figcaption>
                                        <strong>Discount </strong>
                                        <small className="text-right">
                                          <strong>
                                            <span
                                              style={{ fontSize: "11.5px" }}
                                            >
                                              {" "}
                                              (£
                                              {parseFloat(
                                                negotiated_price
                                              )?.toFixed(2)}
                                              )
                                            </span>{" "}
                                            £
                                            {(
                                              parseFloat(subTotal_price) -
                                              parseFloat(negotiated_price)
                                            )?.toFixed(2)}
                                          </strong>
                                        </small>
                                      </figcaption>
                                      {isNonNegotiable ? (
                                        <>
                                          <figcaption className="checkout-nonnego">
                                            <strong>Non Negotiate Cart</strong>
                                            <small className="text-right">
                                              <strong>
                                                £
                                                {parseFloat(
                                                  nonNegotiatedtotal
                                                )?.toFixed(2)}
                                              </strong>
                                            </small>
                                          </figcaption>
                                          <figcaption className="checkout-total">
                                            <strong>Total </strong>
                                            <small className="text-right">
                                              <strong>
                                                £
                                                {(
                                                  parseFloat(
                                                    nonNegotiatedtotal
                                                  ) +
                                                  parseFloat(negotiated_price)
                                                )?.toFixed(2)}
                                              </strong>
                                            </small>
                                          </figcaption>
                                        </>
                                      ) : (
                                        <figcaption className="checkout-total">
                                          <strong>Total </strong>
                                          <small className="text-right">
                                            <strong>
                                              £
                                              {(
                                                parseFloat(nonNegotiatedtotal) +
                                                parseFloat(negotiated_price)
                                              )?.toFixed(2)}
                                            </strong>
                                          </small>
                                        </figcaption>
                                      )}
                                    </>
                                  )}
                                </figure>
                                {payment || calculated_status ? (
                                  <>
                                    <figure>
                                      <figcaption>
                                        <strong>VAT</strong>
                                        <small>
                                          <strong>
                                            £
                                            {congestion == 1
                                              ? "0.00"
                                              : vatPrice.toFixed(2)}
                                          </strong>
                                        </small>
                                      </figcaption>
                                      {congestion == 1 && (
                                        <figcaption>
                                          <strong>Congestion Charge</strong>
                                          <small>
                                            <strong>£15.00</strong>
                                          </small>
                                        </figcaption>
                                      )}
                                    </figure>
                                    <figure>
                                      <figcaption>
                                        <strong>Shipping Fee</strong>
                                        <small>
                                          <strong>
                                            £
                                            {parseFloat(shippingfess).toFixed(
                                              2
                                            )}
                                          </strong>
                                        </small>
                                      </figcaption>
                                    </figure>
                                    <figure className="ps-block__total mb-0 pb-0 border-bottom-none">
                                      <h3>
                                        Grand Total
                                        <strong>£{MainTotal.toFixed(2)}</strong>
                                      </h3>
                                    </figure>
                                  </>
                                ) : null}
                              </div>
                            </div>

                            {calculated_status == false ? (
                              <div className="ps-block__footer">
                                <button className="ps-btn bg-blue color-white w-100">
                                  Calculate Shipping & Payment
                                </button>
                              </div>
                            ) : null}
                            {noDelivering === false ? (
                              <>
                                {calculated_status ? (
                                  <div className="mt-20">
                                    <h4 className="mb-15">Payment Methods</h4>
                                    <div className="ps-block--payment-method">
                                      <div className="ps-block__header">
                                        <Radio.Group
                                          onChange={(e) =>
                                            handleChangeMethod(e)
                                          }
                                          value={method}
                                        >
                                          <Radio value={3}>
                                            Credit / Debit Card
                                          </Radio>
                                        </Radio.Group>
                                      </div>
                                    </div>
                                    <div className="ps-block__footer">
                                      {method == 3 ? (
                                        <div className="">
                                          <a
                                            className="ps-btn bg-blue color-white text-center w-100 mb-2"
                                            onClick={FinalCheckout}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(255, 182, 0)",
                                                fontWeight: "650",
                                              }}
                                            >
                                              {" "}
                                              £{MainTotal.toFixed(2)}
                                            </span>{" "}
                                            - Pay with Credit / Debit Card
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div className="text-center text-danger mt-3">
                                <Alert
                                  message="We're sorry! Currently, we can’t deliver
                                      to this pincode. Please choose another!"
                                  type="error"
                                  className="text-danger"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>

      <Modal
        open={open}
        title="Stripe Payment"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <Elements stripe={stripePayment}>
          <CheckoutForm MainTotal={MainTotal} />
        </Elements>
      </Modal>

      <Modal
        open={deliverOpen}
        title="Delivery Status"
        width={850}
        centered
        onOk={() => setDeliveryOpen(false)}
        onCancel={() => setDeliveryOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <div>
          <table role="table" className="table-striped table">
            <thead className="">
              <tr role="row">
                <th>Id</th>
                <th>Product Name</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>VAT</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {deliveryProducts?.map((obj, i) => {
                const vatCal = parseFloat(obj.price) * 0.2;
                return (
                  <tr
                    role="row"
                    className={obj.delivery == 0 ? "red-mark" : ""}
                    key={i}
                  >
                    <td className="ant-table-cell">
                      {obj.delivery == 0 ? (
                        <Tooltip
                          title="This product can’t be delivered to the provided pincode. We’ll remove those from your cart, and you can proceed with the remaining order"
                          placement="bottom"
                          color={"#fc5363"}
                        >
                          <a style={{ paddingRight: "3px" }}>
                            <i className="icon-question-circle "></i>
                          </a>
                        </Tooltip>
                      ) : null}
                      {obj.id}
                    </td>
                    <td>{obj.name}</td>
                    <td>{obj.qty}</td>
                    <td>£{parseFloat(parseFloat(obj.price))?.toFixed(2)}</td>
                    <td>{parseFloat(vatCal).toFixed(2)}</td>
                    <td>
                      £{(parseFloat(obj.price) + parseFloat(vatCal)).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="">
            Note : One or more of the items marked in red can’t be delivered to
            the provided pincode. We’ll remove those from your cart, and you can
            proceed with the remaining order. Thank you for your understanding!
          </div>
          <div className="mt-3 text-right">
            <a
              className="ps-btn bg-blue color-white text-center  mb-2"
              onClick={onRemoveNonDeliver}
            >
              Remove and Continue with Payment
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Checkout;
